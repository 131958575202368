import React from "react";

const TrainListheader = ()=>{
    
    return(
        <div>
            <h1 className='uppercase text-center py-16 text-2xl md:text-3xl font-bold'>Train List</h1>
        </div>
    )
}

export default TrainListheader;